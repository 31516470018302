import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const UpgradeSection = () => {
  const [userPlan, setUserPlan] = useState(null);
  const [userLink, setUserLink] = useState(''); // Armazena o link para o usuário não Premium
  const [premiumLink, setPremiumLink] = useState(''); // Armazena o link para o grupo Premium

  // Função para buscar o plano do usuário
  const fetchUserPlan = () => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const { plano_do_usuario } = data.usuario;
          setUserPlan(plano_do_usuario);

          // Se o plano for Premium, chama a API para pegar o link do grupo Premium
          if (plano_do_usuario === 'Premium') {
            fetchPremiumLink(); // Busca o link do grupo Premium
          } else {
            fetchLinkFromAPI(); // Para Free, busca o link do pagamento
          }
        })
        .catch((err) => console.error('Erro ao buscar o usuário:', err));
    }
  };

  // Função para buscar o link do grupo Premium
  const fetchPremiumLink = () => {
    fetch('https://painelbackend.whatsapchat.com.br/api/link-grupo') // Endpoint para o link do grupo Premium
      .then((response) => response.json())
      .then((data) => {
        // Aqui, a API retorna um objeto { "link": "url" }
        setPremiumLink(data.link); // Armazena o link do grupo Premium
      })
      .catch((err) => console.error('Erro ao buscar o link do grupo Premium:', err));
  };

  // Função para buscar o link para usuários não Premium
  const fetchLinkFromAPI = () => {
    fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento') // Endpoint para links não Premium
      .then((response) => response.json())
      .then((data) => {
        // Aqui a API retorna um objeto { "link": "url" }
        setUserLink(data.link); // Armazena o link retornado da API
      })
      .catch((err) => console.error('Erro ao buscar o link:', err));
  };

  // Chama a função ao montar o componente
  useEffect(() => {
    fetchUserPlan();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#004D40',
        textAlign: 'center',
        py: 4,
        border: '1px solid #BCBCBE',
        borderRadius: 5,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography
          variant="h5"
          color="#EDF2FA"
          sx={{
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          {userPlan === 'Premium' ? 'VOCÊ JÁ É PREMIUM' : 'CLIQUE NO BOTÃO E SEJA VIP'}
        </Typography>
      </Box>

      <Typography
        variant="h6"
        color="#FFFFFF"
        sx={{
          fontSize: '1rem',
          mt: 2,
          textAlign: 'center',
          maxWidth: '80%',
          margin: '0 auto',
          lineHeight: 1.5,
        }}
      >
        {userPlan === 'Premium'
          ? 'Aproveite seus benefícios como usuário Premium e acesse nosso grupo!'
          : 'DE: R$49,90 POR: R$24,99'}
      </Typography>

      <Button
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: '#028069',
          '&:hover': {
            backgroundColor: '#028069',
          },
          animation: 'pulse 1.5s ease-in-out infinite',
        }}
        href={userPlan === 'Premium' ? premiumLink : userLink} // Usa o link do grupo Premium ou do pagamento
        target={userPlan === 'Premium' ? '_blank' : '_blank'} // Abre o link em nova aba caso seja Premium
      >
        <Typography
          sx={{
            color: userPlan === 'Premium' ? '#FFFFFF' : '#000000', // Define a cor do texto
          }}
        >
          {userPlan === 'Premium' ? 'acessar o grupo!' : 'Clique aqui para ser vip'}
        </Typography>
        <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20, color: '#FFFFFF' }} />
      </Button>

      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default UpgradeSection;
