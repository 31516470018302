import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';

const Tinder = () => {
    const [currentProfile, setCurrentProfile] = useState(0); // Estado para o perfil atual
    const [profiles, setProfiles] = useState([]); // Estado para armazenar os perfis

    // UseEffect para carregar os dados da API
    useEffect(() => {
        const fetchTinder = async () => {
            try {
                const response = await fetch('https://painelbackend.whatsapchat.com.br/api/tinder');
                const data = await response.json();
                setProfiles(data); // Atualiza o estado com os dados da API
            } catch (error) {
                console.error('Erro ao buscar os perfis:', error);
            }
        };
        fetchTinder();
    }, []);

    // Função para passar para o próximo perfil
    const handleLike = () => {
        if (currentProfile < profiles.length - 1) {
            setCurrentProfile(currentProfile + 1); // Passa para o próximo perfil
        } else {
            setCurrentProfile(0); // Se chegar ao último perfil, volta ao primeiro
        }
    };

    // Função para voltar ao perfil anterior
    const handleReplay = () => {
        if (currentProfile > 0) {
            setCurrentProfile(currentProfile - 1); // Volta para o perfil anterior
        } else {
            setCurrentProfile(profiles.length - 1); // Se estiver no primeiro perfil, vai para o último
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '85vh', // Preenche a altura total da tela
                backgroundColor: '#EDF2FA', // Cor de fundo do container
            }}
        >
            <Box
                sx={{
                    width: 400,
                    height: 570,
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                    backgroundColor: '#EDF2FA',
                    position: 'relative',
                }}
            >
                {/* Verifica se há perfis antes de renderizar */}
                {profiles.length > 0 && (
                    <>
                        {/* Imagem */}
                        <Box
                            sx={{
                                width: '100%',
                                height: '60%',
                                background: `url(${profiles[currentProfile].image}) center/contain no-repeat`,
                            }}
                        ></Box>

                        {/* Informações do Perfil */}
                        <Box
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="body2"
                                fontWeight="bold"
                                sx={{ fontSize: '1.5rem', color: '#020202' }} // Ajusta o tamanho da fonte e a cor
                            >
                                {profiles[currentProfile].name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mt: 1, fontSize: '1.2rem', color: '#020202' }} // Ajusta o tamanho da fonte e a cor
                            >
                                {profiles[currentProfile].description}
                            </Typography>
                        </Box>

                        {/* Botões com fundo de "vidro" */}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 20,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-around',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)', // Cor de fundo transparente
                                backdropFilter: 'blur(10px)', // Adiciona o efeito de desfoque
                                borderRadius: '10px', // Arredonda os cantos do box
                                padding: '10px 0', // Adiciona algum espaçamento
                            }}
                        >
                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                }}
                                onClick={handleReplay} // Chama a função para voltar ao perfil anterior
                            >
                                <ReplayIcon sx={{ color: '#FFA500', fontSize: 28 }} />
                            </IconButton>

                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                }}
                                onClick={handleLike}
                            >
                                <CloseIcon sx={{ color: '#FF3E4D', fontSize: 28 }} />
                            </IconButton>

                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                }}
                                onClick={handleLike} // Passa para o próximo perfil
                            >
                                <FavoriteIcon sx={{ color: '#4CAF50', fontSize: 28 }} />
                            </IconButton>

                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
                                }}
                            >
                                <StarIcon sx={{ color: '#00BFFF', fontSize: 28 }} />
                            </IconButton>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Tinder;
