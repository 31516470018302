import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const imagesPagos = [
  'https://e-volutionn.com/wp-content/uploads/2024/12/7-1.png'
];

const imagesFree = [
  'https://e-volutionn.com/wp-content/uploads/2024/12/6-1.png',
  'https://e-volutionn.com/wp-content/uploads/2024/12/7-1.png',
  'https://e-volutionn.com/wp-content/uploads/2024/12/5-1.png',
];

const Banner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [userPlan, setUserPlan] = useState(''); // Armazena o plano do usuário
  const [images, setImages] = useState(imagesFree); // Inicializa com as imagens free
  const [links, setLinks] = useState([]); // Inicializa os links como um array vazio

  // Função para buscar o plano do usuário
  const fetchUserPlan = () => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const { plano_do_usuario } = data.usuario;
          setUserPlan(plano_do_usuario);
          // Se o plano for Premium, atualiza as imagens e os links pagos
          if (plano_do_usuario === 'Premium') {
            setImages(imagesPagos);
            fetchLinkGrupo(); // Se Premium, busca os links pagos
          } else {
            setImages(imagesFree);
            fetchLinkFromAPI(); // Caso Free, chama a API para buscar os links
          }
        })
        .catch((err) => console.error('Erro ao buscar o usuário:', err));
    }
  };

  // Função para buscar os links pagos da API
  const fetchLinkGrupo = () => {
    fetch('https://painelbackend.whatsapchat.com.br/api/link-grupo') // Endpoint para os links pagos
      .then((response) => response.json())
      .then((data) => {
        // Aqui, a API retorna um objeto { "link": "url" }
        const fetchedLink = data.link;
        // Definindo os links da API para as imagens pagas
        setLinks(new Array(imagesPagos.length).fill(fetchedLink)); // Preenche os links com o valor retornado da API
      })
      .catch((err) => console.error('Erro ao buscar os links pagos:', err));
  };

  // Função para buscar o link para as imagens free
  const fetchLinkFromAPI = () => {
    fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento') // Endpoint para os links gratuitos
      .then((response) => response.json())
      .then((data) => {
        // Aqui, a API retorna um objeto { "link": "url" }
        const fetchedLink = data.link;
        // Definindo o link da API para as imagens free
        setLinks(new Array(imagesFree.length).fill(fetchedLink)); // Preenche os links com o valor retornado da API
      })
      .catch((err) => console.error('Erro ao buscar os links gratuitos:', err));
  };

  useEffect(() => {
    fetchUserPlan(); // Busca o plano do usuário ao carregar o componente

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Altere o intervalo conforme necessário

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
  }, [images]); // Dependência no array de imagens

  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        py: 6,
        backgroundColor: 'grey.800',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px', // Adiciona borda arredondada de 16px
        cursor: 'pointer', // Adiciona um cursor pointer para indicar clicável
      }}
      onClick={() => window.open(links[currentImageIndex], '_blank')} // Redireciona ao link no clique
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.8,
          transition: 'background-image 1s ease-in-out', // Transição suave ao trocar de imagem
          borderRadius: '16px',
        }}
      />
    </Box>
  );
};

export default Banner;
