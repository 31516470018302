import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null); // Estado para a foto do usuário
  const location = useLocation();
  const navigate = useNavigate();

  const UserEmail = localStorage.getItem('userEmail'); // Substitua pelo e-mail do usuário autenticado

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://backend.whatsapchat.com.br/usuarios/email/${UserEmail}`
        );
        const result = await response.json();
        setUserPhoto(result.usuario.perfil_foto); // Ajuste o campo 'fotoUrl' de acordo com a API
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, [UserEmail]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const DrawerList = ({ toggleDrawer }) => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Divider />
      <Divider />
      <List>
        {['Home', 'WhatsApp', 'Tinder', 'Diversão'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                if (index === 0) navigate('/');
                if (index === 1) navigate('/whatsapp');
                if (index === 2) navigate('/tinder');
                if (index === 3) navigate('/games');
              }}
            >
              <ListItemIcon>
                {index === 0 && <HomeIcon sx={{ color: '#028069' }} />}
                {index === 1 && <WhatsAppIcon sx={{ color: '#028069' }} />}
                {index === 2 && <WhatshotIcon sx={{ color: '#028069' }} />}
                {index === 3 && <SportsEsportsIcon sx={{ color: '#028069' }} />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Divider />
    </Box>
  );

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Início';
      case '/whatsapp':
        return 'WhatsApp';
      case '/tinder':
        return 'Tinder';
      case '/games':
        return 'Games';
      case '/perfil':
        return 'Perfil';
      case '/conteudos':
        return 'Conteudos';
      default:
        return 'Página';
    }
  };

  return (
    <div>
      <AppBar position="sticky" sx={{ backgroundColor: '#028069' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, color: '#ffffff' }}>
            {getPageTitle()}
          </Typography>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            {userPhoto ? (
              <Avatar src={userPhoto} alt="Foto do Usuário" />
            ) : (
              <Avatar sx={{ bgcolor: '#FFF200' }}>U</Avatar> // Ícone padrão se não houver foto
            )}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                backgroundColor: '#343434',
                color: '#ffffff',
                width: 250,
              },
            }}
          >
            <MenuItem onClick={() => navigate('/perfil')}>
              <ListItemIcon>
                <Avatar src={userPhoto} alt="Foto do Usuário" sx={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText primary="Perfil" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.clear();
                navigate('/login');
              }}
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: '#1CD76C' }} />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#343434',
            color: '#ffffff',
          },
        }}
      >
        {DrawerList({ toggleDrawer })}
      </Drawer>
      <Divider sx={{ borderColor: '#FFFFF', margin: '0.5px 0' }} />
    </div>
  );
};

export default Header;
