// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0c0c0c;
  background-color: #EDF2FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content {
  padding: 10px;
  min-height: 60vh; /* Garante que o conteúdo ocupe pelo menos 80% da altura da tela */
}

.plano-conet {
  padding: 10px;
  margin-bottom: 05px; /* Reduzindo o espaçamento entre o Banner e o UpgradeSection */
}

.games-section {
  min-height: 150px;
}

footer {
  position: relative;
  z-index: 1;
  padding: 20px;
}

.instalacao-wrapper {
  padding: 20px;
  margin-bottom: 60px; /* Ajustando o espaçamento para que o InstalacaoApp não fique cortado */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,aAAa;EACb,gBAAgB,EAAE,kEAAkE;AACtF;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,8DAA8D;AACrF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,uEAAuE;AAC9F","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  color: #0c0c0c;\n  background-color: #EDF2FA;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.content {\n  padding: 10px;\n  min-height: 60vh; /* Garante que o conteúdo ocupe pelo menos 80% da altura da tela */\n}\n\n.plano-conet {\n  padding: 10px;\n  margin-bottom: 05px; /* Reduzindo o espaçamento entre o Banner e o UpgradeSection */\n}\n\n.games-section {\n  min-height: 150px;\n}\n\nfooter {\n  position: relative;\n  z-index: 1;\n  padding: 20px;\n}\n\n.instalacao-wrapper {\n  padding: 20px;\n  margin-bottom: 60px; /* Ajustando o espaçamento para que o InstalacaoApp não fique cortado */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
