import React from 'react';
import { Box, Typography, Badge } from '@mui/material';
import { Gamepad as GamepadIcon } from '@mui/icons-material'; // Ícone de jogo

const Marquee = () => {
  // Lista de pessoas
  const people = [
    { name: 'Fernanda', status: 'acabou de ficar online', iconColor: '#D0B107' },
    { name: 'Eduardo', status: 'ganhou R$25,26 no Crash', iconColor: '#00FF00' },
    { name: 'Kamila', status: 'acessou o sistema', iconColor: '#FF5733' },
    // Adicione mais pessoas conforme necessário
  ];

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
        backgroundColor: '#004D40',
        borderRadius: '10px',
        py: 1, // Espaço acima e abaixo
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          animation: 'marquee 10s linear infinite',
        }}
      >
        {people.map((person, index) => (
          <Badge
            key={index}
            color="error"
            sx={{
              padding: '0.3rem', // Diminui o padding do Badge
              display: 'inline-flex',
              alignItems: 'center',
              backgroundColor: '#333333', // Fundo do Badge
              borderRadius: 3.5, // Bordas arredondadas
              position: 'relative',
              overflow: 'hidden', // Garantir que o reflexo não vaze
              marginRight: 1, // Menos espaço entre os badges
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-100%',
                width: '150%',
                height: '100%',
                background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)',
                animation: 'shine 2s infinite',
              },
            }}
          >
            <Typography
              sx={{
                color: '#FFFFFF', // Cor branca para o nome da pessoa
                fontWeight: 'bold',
                marginRight: 0.3, // Reduz a margem entre os textos
                fontSize: '0.8rem', // Reduz o tamanho da fonte
              }}
            >
              {person.name}
            </Typography>
            <Typography
              sx={{
                color: person.iconColor,
                fontSize: '0.8rem', // Tamanho da fonte menor
                fontWeight: 'bold',
              }}
            >
              {person.status}
            </Typography>
          </Badge>
        ))}
      </Box>

      {/* Animação de reflexo */}
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
          @keyframes shine {
            0% { left: -100%; }
            100% { left: 100%; }
          }
        `}
      </style>
    </Box>
  );
};

export default Marquee;

