import React, { useState, useEffect } from 'react';
import { Box, Avatar, IconButton, Dialog, DialogContent, Typography, DialogTitle, DialogActions, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const StorysDisponiveis = () => {
  const [selectedPhoto, setSelectedPhoto] = useState(null); // Armazena a foto selecionada
  const [openModal, setOpenModal] = useState(false); // Controla a abertura do modal
  const [randomPeople, setRandomPeople] = useState([]); // Lista de pessoas aleatórias
  const [currentIndex, setCurrentIndex] = useState(0); // Índice da imagem atual
  const [userPlan, setUserPlan] = useState(''); // Armazena o plano do usuário
  const [openDialog, setOpenDialog] = useState(false); // Controla a abertura do dialog
  const [counter, setCounter] = useState(1); // Começa com o número 1
  const [peopleData, setPeopleData] = useState([]);
  const [upgradeLink, setUpgradeLink] = useState(''); // Novo estado para armazenar o link de upgrade

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await fetch('https://painelbackend.whatsapchat.com.br/api/stories');
        const data = await response.json();
        setPeopleData(data);
        setRandomPeople(data);
      } catch (error) {
        console.error('Erro ao buscar os stories:', error);
      }
    };

    fetchStories();
    fetchUserPlan(); // Mantém a chamada para o plano do usuário
  }, []);

  const getRandomPeople = () => {
    const shuffled = [...peopleData].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 50);
  };

  useEffect(() => {
    setRandomPeople(getRandomPeople());
    fetchUserPlan(); // Verifica o plano do usuário ao carregar
  }, []);

  useEffect(() => {
    if (userPlan === 'Basico') {
      const intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter + 1); // Incrementa 5 a cada 5 segundos
      }, 30000);

      // Limpa o intervalo quando o componente for desmontado ou o plano mudar
      return () => clearInterval(intervalId);
    }
  }, [userPlan]); // Dependência de 'userPlan' para resetar o intervalo se o plano mudar

  const fetchUserPlan = () => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const { plano_do_usuario, link_upgrade } = data.usuario;
          setUserPlan(plano_do_usuario);
          setUpgradeLink(link_upgrade); // Armazena o link de upgrade
        })
        .catch((err) => console.error('Erro ao buscar o usuário:', err));
    }
  };

  const handleStoryClick = (photo, index) => {
    if (index === 0 && userPlan === 'Basico') {
      setOpenDialog(true); // Se for plano Básico e o primeiro story, abre o Dialog
    } else {
      setSelectedPhoto(photo);
      setCurrentIndex(index);
      setOpenModal(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPhoto(null);
  };

  const handlePrevPhoto = () => {
    const prevIndex = currentIndex === 0 ? randomPeople.length - 1 : currentIndex - 1;
    setSelectedPhoto(randomPeople[prevIndex].photo);
    setCurrentIndex(prevIndex);
  };

  const handleNextPhoto = () => {
    const nextIndex = currentIndex === randomPeople.length - 1 ? 0 : currentIndex + 1;
    setSelectedPhoto(randomPeople[nextIndex].photo);
    setCurrentIndex(nextIndex);
  };

  return (
    <Box sx={{ p: 0 }}>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          paddingBottom: '10px',
          scrollBehavior: 'smooth',
          borderRadius: '15px',
          border: '1px solid #EDF2FA',
          '&::-webkit-scrollbar': { height: '4px' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#C1C1C1', borderRadius: '2px' },
          '&::-webkit-scrollbar-track': { backgroundColor: '#E0F7FA' },
        }}
      >
        {randomPeople.map((person, index) => (
          <Box
            key={index}
            sx={{
              flex: 'none',
              width: 70,
              marginRight: 0.5,
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <IconButton onClick={() => handleStoryClick(person.photo, index)}>
              <Avatar
                src={person.photo}
                alt={person.name}
                sx={{
                  width: 56,
                  height: 56,
                  border: '3px solid #1CD76C',
                  filter: index === 0 && userPlan === 'Basico' ? 'blur(4px)' : 'none', // Aplica o blur apenas no primeiro story
                }}
              />
            </IconButton>
            {index === 0 && userPlan === 'Basico' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-45%, -60%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  opacity: 0.9, // Manter a visibilidade do número
                  cursor: 'pointer', // Indica que a área é clicável
                }}
                onClick={() => handleStoryClick(person.photo, index)} // Ação ao clicar no número
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '18px', // Ajuste do tamanho da fonte
                    color: '#fff',
                    fontWeight: 'bold',
                    textAlign: 'center', // Alinha o texto ao centro
                  }}
                >
                  {counter} {/* Exibe o número que está sendo incrementado */}
                </Typography>
              </Box>
            )}

            {userPlan === 'Basico' && (
              <Typography
                variant="caption"
                sx={{
                  color: '#000',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  marginTop: '5px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {index === 0 ? 'Curtiu você' : ''}
              </Typography>
            )}
          </Box>
        ))}
      </Box>

      {/* Dialog para planos básicos */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            backgroundColor: '#222B36',
            color: '#FFF',
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#028069',
          }}
        >
          Acesso Restrito
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center">
            Esta conversa é exclusiva para membros do plano <b>Pro</b>. Adquira o plano para desbloquear todas as conversas
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#028069',
              '&:hover': {
                backgroundColor: '#028069',
              },
              animation: 'pulse 1.5s ease-in-out infinite',
              mt: 2,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            onClick={async () => {
              try {
                // Fazendo a requisição para o endpoint de link de pagamento
                const response = await fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento');
                const data = await response.json();

                // Verificando se a resposta contém o link de pagamento
                if (data && data.link) {
                  // Redirecionando para o link de pagamento
              
                  window.open(data.link, '_blank');
                } else {
                  console.error('Link de pagamento não encontrado.');
                }
              } catch (error) {
                console.error('Erro ao buscar o link de pagamento:', error);
              }
            }}
          >
            FAÇA AGORA SEU UPGRADE
            <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20 }} />
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para exibir o story */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        sx={{ '& .MuiDialog-paper': { backgroundColor: 'transparent', boxShadow: 'none' } }}
      >
        <DialogContent sx={{ p: 0, display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <IconButton
            onClick={handlePrevPhoto}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 10,
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <img
            src={selectedPhoto}
            alt="Story"
            style={{ width: '100%', maxWidth: 600, borderRadius: 8 }}
          />

          <IconButton
            onClick={handleNextPhoto}
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 10,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StorysDisponiveis;
