import React, { useState } from 'react';
import { Box, TextField, Typography, Button, CircularProgress } from '@mui/material';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      // Primeira requisição POST para criar o usuário
      const response = await fetch('https://backend.whatsapchat.com.br/usuarios', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: 'Usuario Novo',
          email: email,
          perfil_foto: 'https://linkdofoto.com/foto.jpg',
          plano_do_usuario: 'Basico',
        }),
      });

      if (response.status === 201 || response.status === 400) {
        // Requisição GET para buscar os dados do usuário
        const userResponse = await fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`);
        const userData = await userResponse.json();

        if (userResponse.status === 200) {
          // Armazena o email e o token no localStorage
          localStorage.setItem('userEmail', email);
          localStorage.setItem('token', 'DummyTokenHere');
          setLoading(false);
          window.location.href = '/'; // Redireciona para a página de boas-vindas
        } else {
          setErrorMessage('Erro ao buscar os dados do usuário.');
        }
      } else {
        alert('Erro no servidor');
      }
    } catch (err) {
      setErrorMessage('Erro ao conectar com a API.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#05111F',
      }}
    >
      <Box
        sx={{
          width: 350,
          padding: 4,
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#0A1A2B',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            color: '#FFF',
            marginBottom: 2,
            fontWeight: 'bold',
          }}
        >
          Bem-vindo
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: '#B0BEC5',
            marginBottom: 4,
          }}
        >
          Por favor, insira seu e-mail para continuar
        </Typography>
        <TextField
          fullWidth
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            marginBottom: 3,
            '& .MuiInputBase-root': {
              backgroundColor: '#1C2A3A',
              color: '#FFF',
            },
            '& .MuiInputLabel-root': {
              color: '#FFFF',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#394B59',
              },
              '&:hover fieldset': {
                borderColor: '#028069',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#028069',
              },
            },
          }}
        />
        {errorMessage && (
          <Typography
            variant="body2"
            sx={{ color: 'red', marginBottom: 2, textAlign: 'center' }}
          >
            {errorMessage}
          </Typography>
        )}
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          onClick={handleLogin}
          sx={{
            backgroundColor: '#028069',
            color: '#FFFFF',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1CD76C',
            },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: '#FFFFF' }} /> : 'Entrar'}
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
