import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AppleIcon from '@mui/icons-material/Apple';
import AdbIcon from '@mui/icons-material/Adb';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';

const InstalacaoApp = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');

  const handleOpenDialog = (platform) => {
    setSelectedPlatform(platform);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPlatform('');
  };

  const steps = {
    iOS: [
      'Abra o aplicativo no Safari',
      'Clique no ícone de "compartilhamento" no canto inferior do smartphone',
      'Clique em "Adicionar à tela de início"',
      'Clique em "Adicionar" no canto superior direito',
    ],
    Android: [
      'Acesse o Google Play no seu dispositivo',
      'Busque pelo nosso aplicativo',
      'Toque em "Instalar" e siga as instruções',
      'Abra o aplicativo e comece a usar',
    ],
  };

  return (
    <Box sx={{ p: 4, paddingTop: '10px' }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#020202',
        }}
      >
        <DownloadForOfflineOutlinedIcon sx={{ mr: 1, color: '#028069' }} />
        Instalação do App
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleOpenDialog('iOS')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            backgroundColor: '#434343',
            '&:hover': {
              backgroundColor: '#434343',
            },
            minWidth: '150px',
            fontFamily: 'Arial, sans-serif',
            textTransform: 'none',
          }}
        >
          <AppleIcon sx={{ mr: 1, fontSize: 24 }} />
          Apple (iOS)
        </Button>

        <Button
          variant="contained"
          onClick={() => handleOpenDialog('Android')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            backgroundColor: '#434343',
            '&:hover': {
              backgroundColor: '#434343',
            },
            minWidth: '150px',
            fontFamily: 'Arial, sans-serif',
            textTransform: 'none',
          }}
        >
          <AdbIcon sx={{ mr: 1, fontSize: 24 }} />
          Android
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { backgroundColor: '#202024', color: '#FFFFFF', borderRadius: '10px' },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#202024',
            color: '#FFF200',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          Instalação {selectedPlatform}
          <IconButton onClick={handleCloseDialog} sx={{ color: '#FFFFFF' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ color: '#BCBCBE', marginBottom: 2 }}>
            Siga os passos para instalar de maneira correta no seu smartphone
          </Typography>
          <List>
            {steps[selectedPlatform]?.map((step, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: '#FFF200', color: '#202024' }}>
                    {index === 0 && <LooksOneIcon />}
                    {index === 1 && <LooksTwoIcon />}
                    {index === 2 && <Looks3Icon />}
                    {index === 3 && <Looks4Icon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={step} sx={{ color: '#FFFFFF' }} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default InstalacaoApp;
