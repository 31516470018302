import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import UpgradeSection from '../components/UpgradeSection';
import GamesSection from '../components/Storys';
import Footer from '../components/Footer';
import StorysDisponiveis from '../components/Storys';
import Marquee from '../components/Marquee';
import InstalacaoApp from '../components/Instalacao';
import axios from 'axios'; // Adicionei o axios para facilitar a requisição

const HomePage = () => {
    const userEmail = localStorage.getItem('userEmail');
    const [status, setStatus] = useState(null); // Estado para armazenar o status da resposta da API

    useEffect(() => {
        // Função para chamar o endpoint
        const fetchPaymentStatus = async () => {
            try {
                const response = await axios.get(`https://backend.whatsapchat.com.br/processar-pagamentos/${userEmail}`);
                setStatus(response.data.message); // Atualiza o estado com a resposta da API
            } catch (error) {
                console.error('Erro ao chamar a API:', error);
                setStatus('Erro ao processar pagamentos'); // Atualiza o estado com erro, se necessário
            }
        };

        // Chamada inicial
        fetchPaymentStatus();

        // Configura o intervalo para chamar o endpoint a cada 10 segundos
        const intervalId = setInterval(() => {
            fetchPaymentStatus();
        }, 10000); // 10000ms = 10 segundos

        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(intervalId);
    }, [userEmail]); // Dependência de userEmail para refazer a chamada caso o email seja alterado

    return (
        <div>
            <div className="content">
                <StorysDisponiveis />
                <Marquee />
                <Banner />
            </div>
            <div className="plano-conet">
                <UpgradeSection />
            </div>
            <div className="instalacao-wrapper">
                <InstalacaoApp />
            </div>

        </div>
    );
};

export default HomePage;
