import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
} from '@mui/material';

const Catalogo = () => {
  const [sections, setSections] = useState([]); // Estado para armazenar as seções
  const [loading, setLoading] = useState(true); // Estado para indicar o carregamento
  const [error, setError] = useState(null); // Estado para armazenar erros

  // Função para buscar os dados da API
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch('https://painelbackend.whatsapchat.com.br/api/sections');
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados.');
        }
        const data = await response.json();
        setSections(data); // Atualiza o estado com os dados recebidos
      } catch (err) {
        setError(err.message); // Atualiza o estado com a mensagem de erro
      } finally {
        setLoading(false); // Define que o carregamento terminou
      }
    };

    fetchSections();
  }, []);

  if (loading) {
    return <Typography>Carregando...</Typography>; // Mostra enquanto carrega
  }

  if (error) {
    return <Typography color="error">Erro: {error}</Typography>; // Mostra o erro, se houver
  }

  // Função para redirecionar para o link do item
  const handleRedirect = (url) => {
    window.open(url, '_blank');
    
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EDF2FA',
        color: '#004D40',
        minHeight: '100vh',
        p: 2,
        pb: 8, // Espaçamento inferior para evitar que o conteúdo fique colado no footer
      }}
    >
      {sections.map((section, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          {/* Cabeçalho da seção */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {section.title}
            </Typography>
          </Box>

          {/* Container com rolagem horizontal */}
          <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2 }}>
            {section.items.map((item) => (
              <Box key={item.id} sx={{ minWidth: 200 }}>
                <Card
                  sx={{
                    backgroundColor: '#028069',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    ':hover': { transform: 'scale(1.05)' },
                    transition: 'transform 0.3s',
                  }}
                  onClick={() => handleRedirect(item.url_redirecionamento)} // Adiciona o clique no card
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={item.image}
                    alt={item.title}
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 'bold', textAlign: 'center', color: '#EDF2FA' }}
                    >
                      {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Catalogo;
