import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import axios from 'axios';
import StorysDisponiveis from '../components/Storys';

const WhatsAppPage = () => {
  const [openStoryDialog, setOpenStoryDialog] = useState(false);
  const [selectedStory, setSelectedStory] = useState('');
  const [openChatIframe, setOpenChatIframe] = useState(false);
  const [selectedChatLink, setSelectedChatLink] = useState('');
  const [userPlan, setUserPlan] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [userLocation, setUserLocation] = useState('Carregando...');
  const [chats, setChats] = useState([]); // Novo estado para armazenar os chats
  const [groupLink, setGroupLink] = useState(''); // Estado para armazenar o link do grupo

  const handleCloseDialog = () => setOpenDialog(false);

  // Função para buscar o plano do usuário
  const fetchUserPlan = () => {
    const email = localStorage.getItem('userEmail');
    if (email) {
      fetch(`https://backend.whatsapchat.com.br/usuarios/email/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const { plano_do_usuario } = data.usuario;
          setUserPlan(plano_do_usuario);
        })
        .catch((err) => console.error('Erro ao buscar o usuário:', err));
    }
  };

  // Função para buscar a localização do usuário
  const fetchUserLocation = () => {
    axios
      .get('http://ip-api.com/json/')
      .then((response) => {
        const { city, region, country } = response.data;
        setUserLocation(`${city}, ${region}, ${country}`);
      })
      .catch((error) => {
        console.error('Erro ao obter a localização:', error);
        setUserLocation('Não foi possível determinar a localização.');
      });
  };

  // Função para buscar os chats da API
  const fetchChats = () => {
    axios
      .get('https://painelbackend.whatsapchat.com.br/api/whatsapp')
      .then((response) => {
        setChats(response.data); // Atualiza o estado com os dados da API
      })
      .catch((error) => {
        console.error('Erro ao buscar os chats:', error);
      });
  };

  // Função para buscar o link do grupo
  const fetchGroupLink = () => {
    fetch('https://painelbackend.whatsapchat.com.br/api/link-grupo') // Substitua pelo endpoint correto
      .then((response) => response.json())
      .then((data) => {
        setGroupLink(data.link); // Armazena o link do grupo
      })
      .catch((err) => console.error('Erro ao buscar o link do grupo:', err));
  };

  // Chama as funções ao montar o componente
  useEffect(() => {
    fetchUserPlan();
    fetchUserLocation();
    fetchChats(); // Chama a função para buscar os chats
    fetchGroupLink(); // Chama a função para buscar o link do grupo
  }, []);

  const handleAvatarClick = (story) => {
    if (story) {
      setSelectedStory(story);
      setOpenStoryDialog(true);
    }
  };

  const handleChatClick = (chat) => {
    if (userPlan === chat.plano || chat.plano === 'Basico') {
      setSelectedChatLink(chat.chatLink);
      setOpenChatIframe(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleGroupButtonClick = () => {
    if (userPlan === 'Premium') {
      window.open(groupLink, '_blank');
      // window.location.href = groupLink; // Redireciona para o link do grupo
    } else {
      setOpenDialog(true); // Abre o diálogo para o upgrade
    }
  };

  return (
    <Box sx={{ backgroundColor: '#EDF2FA', height: '100vh', paddingTop: 2 }}>
      <Box sx={{ mb: 2 }}>
        <StorysDisponiveis />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#028069',
            color: '#FFF',
            '&:hover': { backgroundColor: '#025c4d' },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
          onClick={handleGroupButtonClick} // Chama a função que verifica o plano
        >
          ENTRAR NO GRUPO
        </Button>
      </Box>

      <Typography
        variant="body2"
        sx={{
          color: 'black',
          textAlign: 'center',
          mb: 2,
          fontWeight: 'bold',
          fontSize: '1.0rem',
        }}
      >
        Mulheres online agora em - Feira de Santana
      </Typography>

      {/* Lista de chats */}
      <List>
        {chats.map((chat) => (
          <React.Fragment key={chat.id}>
            <ListItem
              button
              sx={{
                '&:hover': { backgroundColor: '#028069' },
              }}
              onClick={() => handleChatClick(chat)} // Passa o objeto `chat`
            >
              <ListItemAvatar>
                <Avatar
                  src={chat.avatar}
                  alt={chat.name}
                  sx={{
                    border: chat.story ? '3px solid #1CD76C' : 'none',
                    filter: chat.plano === 'Premium' && userPlan !== 'Premium' ? 'blur(3px)' : 'none',
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Impede que o clique no avatar abra o chat
                    handleAvatarClick(chat.story);
                  }}
                >
                  {chat.name.charAt(0)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Typography variant="subtitle1" sx={{ color: '#000000', fontWeight: 'bold' }}>
                    {chat.name}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" sx={{ color: '#BCBCBE' }}>
                    {chat.message}
                  </Typography>
                }
              />

              <Box sx={{ textAlign: 'right', minWidth: '60px' }}>
                <Typography variant="caption" sx={{ color: '#BCBCBE', display: 'block' }}>
                  {chat.time}
                </Typography>
                {chat.unreadMessages > 0 && (
                  <Box
                    sx={{
                      marginTop: '5px',
                      width: 20,
                      height: 20,
                      backgroundColor: '#1DAA61',
                      color: '#FFF',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                      marginLeft: 'auto',
                    }}
                  >
                    {chat.unreadMessages}
                  </Box>
                )}
              </Box>
            </ListItem>

            <Divider sx={{ backgroundColor: '#333' }} />
          </React.Fragment>
        ))}
      </List>

      {/* Dialog para exibir o story */}
      <Dialog open={openStoryDialog} onClose={() => setOpenStoryDialog(false)}>
        <DialogContent sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
          <img src={selectedStory} alt="Story" style={{ maxWidth: '100%', height: 'auto' }} />
        </DialogContent>
      </Dialog>

      {/* Iframe para o chat */}
      {openChatIframe && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#fff' }}>
          <iframe
            src={selectedChatLink}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Chat"
          />
        </Box>
      )}

      {/* Dialog para acesso restrito */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            backgroundColor: '#222B36',
            color: '#FFF',
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#028069',
          }}
        >
          Acesso Restrito
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center">
            Esta conversa é exclusiva para membros do plano <b>Pro</b>. Adquira o plano para desbloquear todas as conversas
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#028069',
              '&:hover': {
                backgroundColor: '#028069',
              },
              animation: 'pulse 1.5s ease-in-out infinite',
              mt: 2,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            onClick={async () => {
              try {
                // Fazendo a requisição para o endpoint de link de pagamento
                const response = await fetch('https://painelbackend.whatsapchat.com.br/api/link-pagamento');
                const data = await response.json();

                // Verificando se a resposta contém o link de pagamento
                if (data && data.link) {
                  // Redirecionando para o link de pagamento
                  window.open(data.link, '_blank');
                  // window.location.href = data.link;
                } else {
                  console.error('Link de pagamento não encontrado.');
                }
              } catch (error) {
                console.error('Erro ao buscar o link de pagamento:', error);
              }
            }}
          >
            FAÇA AGORA SEU UPGRADE
            <ArrowForwardOutlinedIcon sx={{ ml: 1, fontSize: 20 }} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WhatsAppPage;
