import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Avatar,
    Grid,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const Perfil = () => {
    const [data, setData] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [changesMade, setChangesMade] = useState(false);
    const updatedFields = useRef({});

    const UserEmail = localStorage.getItem('userEmail'); // Substitua pelo valor real do e-mail do usuário

    const updateProfile = async () => {
        const updatedData = {
            ...updatedFields.current,
            perfil_foto: avatarUrl || data?.perfil_foto,
        };

        try {
            const response = await fetch(`https://backend.whatsapchat.com.br/usuarios/email/${UserEmail}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            if (response.ok) {
                const result = await response.json();
                alert('Usuário atualizado com sucesso!');
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } else {
                alert('Erro ao atualizar o perfil');
            }
        } catch (err) {
            console.error('Erro ao atualizar o perfil:', err);
        }
    };

    const updateFormValue = ({ updateType, value }) => {
        if (data[updateType] !== value) {
            updatedFields.current = {
                ...updatedFields.current,
                [updateType]: value,
            };
            setChangesMade(true);
        }
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        setAvatar(file);
        const formData = new FormData();
        formData.append('imagem', file);

        fetch('https://backend.whatsapchat.com.br/upload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.fileUrl) {
                    setAvatarUrl(data.fileUrl);
                    setChangesMade(true);
                } else {
                    alert('Erro ao fazer upload da imagem');
                }
            })
            .catch((error) => {
                console.error('Erro ao enviar a imagem para o S3:', error);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://backend.whatsapchat.com.br/usuarios/email/${UserEmail}`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [UserEmail]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ backgroundColor: '#282828', padding: 4, minHeight: '100vh' }}>
            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#FFF',
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight: 'bold',
                }}
            >
                <AccountBoxIcon sx={{ mr: 1, color: '#028069' }} />
                Atualize seu perfil
            </Typography>
            <Grid container spacing={3}>
                {/* Nome */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Nome"
                        fullWidth
                        defaultValue={data.usuario?.nome || ''}
                        onChange={(e) => updateFormValue({ updateType: 'nome', value: e.target.value })}
                        sx={{
                            marginBottom: 3,
                            '& .MuiInputBase-root': {
                                backgroundColor: '#028069', // Cor verde para os inputs
                                color: '#FFF', // Texto branco nos inputs
                            },
                            '& .MuiInputLabel-root': {
                                color: '#FFF', // Cor do label
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#394B59',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#028069',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#028069',
                                },
                            },
                        }}
                    />
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Email"
                        fullWidth
                        disabled
                        defaultValue={data.usuario?.email || ''}
                        onChange={(e) => updateFormValue({ updateType: 'email', value: e.target.value })}
                        sx={{
                            marginBottom: 3,
                            '& .MuiInputBase-root': {
                                backgroundColor: '#028069',
                                color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#FFF',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#394B59',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#028069',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#028069',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#028069',
                                    cursor: 'not-allowed',
                                    '& input': {
                                        color: '#EDF2FA',
                                        '-webkit-text-fill-color': '#EDF2FA',
                                    },
                                },
                            },
                        }}
                    />
                </Grid>

                {/* Plano */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Plano"
                        disabled
                        fullWidth
                        defaultValue={data.usuario?.plano_do_usuario || ''}
                        onChange={(e) => updateFormValue({ updateType: 'plano', value: e.target.value })}
                        sx={{
                            marginBottom: 3,
                            '& .MuiInputBase-root': {
                                backgroundColor: '#028069',
                                color: '#FFF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#FFF',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#394B59',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#028069',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#028069',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#028069',
                                    cursor: 'not-allowed',
                                    '& input': {
                                        color: '#EDF2FA',
                                        '-webkit-text-fill-color': '#EDF2FA',
                                    },
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ marginBottom: 3 }}>
                        <InputLabel sx={{ color: '#FFF' }}>Interesse</InputLabel>
                        <Select
                            label="Status"
                            sx={{
                                marginBottom: 3,
                                backgroundColor: '#028069', // Cor de fundo personalizada
                                '& .MuiInputBase-root': {
                                    color: '#FFF', // Cor do texto nos campos do Select
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#FFF', // Cor do label
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#394B59',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#028069',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#028069',
                                    },
                                },
                                '& .MuiSelect-select': {
                                    color: '#FFF', // Cor do texto da opção selecionada
                                },
                            }}
                        >
                            <MenuItem value="Novas Amizades">Novas Amizades</MenuItem>
                            <MenuItem value="Sexo">Sexo</MenuItem>
                            <MenuItem value="Relacionamento Serio">Relacionamento Serio</MenuItem>
                            <MenuItem value="Conversar">Conversar</MenuItem>
                            <MenuItem value="Casamento">Casamento</MenuItem>
                            <MenuItem value="Comer Casadas">Comer Casadas</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>


                {/* Foto de Perfil */}
                <Grid item xs={12} sm={6} textAlign="center">
                    <Avatar
                        src={avatarUrl || data.usuario?.perfil_foto}
                        alt="Foto de Perfil"
                        sx={{
                            width: 100,
                            height: 100,
                            margin: '0 auto',
                            border: '4px solid #028069',
                        }}
                    />
                    <IconButton color="primary" component="label" sx={{ marginTop: 2 }}>
                        <PhotoCamera sx={{ fontSize: 30, color: '#028069' }} />
                        <input hidden type="file" onChange={handleAvatarChange} />
                    </IconButton>
                </Grid>
            </Grid>


            {/* Botão de Atualizar */}
            <Box mt={4}>
                <Button
                    fullWidth
                    variant="contained"
                    disabled={!changesMade}
                    onClick={updateProfile}
                    sx={{
                        backgroundColor: changesMade ? '#028069' : '#B0BEC5',
                        color: '#282828',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: changesMade ? '#FFD700' : '#B0BEC5',
                        },
                    }}
                >
                    {loading ? <CircularProgress size={24} sx={{ color: '#282828' }} /> : 'Atualizar'}
                </Button>
            </Box>
        </Box>
    );
};

export default Perfil;
